import React from "react";
import {Link} from "gatsby";
import classes from "./BookButton.module.css";

const BookButton = () => {
  return (
    <Link to="/ContactUs" className={classes.book}>Book Now</Link>
  );
}

export default BookButton;