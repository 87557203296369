import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import BookButton from "../components/BookButton/BookButton";
import Container from "../components/Container/Container";
import classes from "./styles/index.module.css";
import Logo from "../images/index/Logo.svg";
import FootprintsSand from "../images/index/footprintsSand.jpg";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>Footprint Podiatry</title>
      </Helmet>
      <div className={classes.root}>
        <div className={classes.navbar}>
          <Navbar isHome />
        </div>

        <div className={classes.content}>
          <div className={classes.heroWrapper}>
            <div className={classes.heroBackground}>
            </div>
            <div className={classes.heroContent}>
              <img src={Logo} width="720" alt="Logo"></img>
              {/* Uncomment for slogan?
              <p className={classes.heroText}>We can walk together</p>
              <p className={classes.heroText}>Thank you for choosing us!</p> */}
              <BookButton />
            </div>
          </div>

          <Container>
            <div className={classes.welcome}>
              <img src={FootprintsSand} alt="placeholder" className={classes.welcomeImage}></img>
              <div className={classes.welcomeText}>
                <h2>Welcome to Footprint Podiatry</h2>
                <p>Footprint was established in 2017, but only recently we have rebranded. Our professional team of Podiatrists love helping people of all ages and background.</p>
                <p>Our team of Accredited Podiatrists are members of Podiatry New Zealand and registered under HPCAA (Health Practitioner Competency Assurance Act).</p>
                <p>Our main location is in Blockhouse Bay, situated at the end of the shopping centre at 2 Kinross Street. It has street level, wheelchair friendly entry off a free public car park.</p>
                <p>As well as private clinical work at Blockhouse Bay, Footprint Podiatry is contracted by 5 Retirement Villages.</p>
              </div>
            </div>

            {/*
            Uncomment for optional reviews?
            <div className={classes.reviews}>
              <h2>See What Our Clients Are Saying About Us...</h2>
              <div className={classes.reviewsSplit}>
                <div className={classes.reviewContainer}>
                  <h3>John Doe</h3>
                  <p>Labore culpa irure eu velit cupidatat id est excepteur non laborum culpa excepteur ea.</p>
                </div>
                <div className={classes.reviewContainer}>
                  <h3>John Doe</h3>
                  <p>Labore culpa irure eu velit cupidatat id est excepteur non laborum culpa excepteur ea.</p>
                </div>
              </div>
            </div>
            */}

            <div className={classes.book}>
              <h2>Book An Appointment With Us Today!</h2>
              <BookButton />
            </div>
          </Container>
        </div>
      </div>

      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  )
}

export default Index;
